:root {
  --epims-green: #409F5A;
  --epims-blue: #2260B0;
  --epims-light-blue: #BFD1E9
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

div.dataCell {
  border-width: 1px;
  border-color: #bfd2ef;
  border-style: solid;
}

div.dataGridCell {
  border-width: 1px;
  border-color: #bfd2ef;
  border-style: solid;
  background-color: #cfe2ff;
  padding-inline: 8px;
}

div.dataGridCellHeader {
  background-color: #9898FF;
  padding-inline: 3px;
}

div.dataGridCellHover {
  background-color: #9ec5fe;
}

div.dataCellEpc {
  font-weight: bold;
}

div.dataCellEpcRatingA {
  background-color: #00c781;
  color: #FFFFFF;
}

div.dataCellEpcRatingB {
  background-color: #19b459;
  color: #FFFFFF;
}

div.dataCellEpcRatingC {
  background-color: #8dce46;
  color: #000000;
}

div.dataCellEpcRatingD {
  background-color: #ffd500;
  color: #000000;
}

div.dataCellEpcRatingE {
  background-color: #fcaa65;
  color: #000000;
}

div.dataCellEpcRatingF {
  background-color: #ef8023;
  color: #000000;
}

div.dataCellEpcRatingG {
  background-color: #e9153b;
  color: #000000;
}

div.dataCellEpcRatingUnknown {
  background-color: #000000;
  color: #FFFFFF;
}

div.dataCellHeader {
  background-color: #9898FF;
}

.spinner {
  animation: spin infinite 1s linear;
}

.spinner-slow {
  animation: spin infinite 5s linear;
}

.pageSection {
  font-size: 2rem;
  font-weight: bold;
}

.pageSubSection {
  font-size: 1.5rem;
  font-weight: bold;
}

.reorderablePlanContainer {
  background-color: #F8F8F8;
  /* border-width: 1px;
  border-color: #000000;
  border-style: solid; */
  width: 350px;
  overflow: hidden;
  display: inline-block;
  margin: 5px;
  padding: 5px;
}

.epcBandA {
  background-color: #00c781;
  color: #FFFFFF;
  font-weight: bold;
}
.epcBandB {
  background-color: #19b459;
  color: #FFFFFF;
  font-weight: bold;
}
.epcBandC {
  background-color: #8dce46;
  color: #000000;
  font-weight: bold;
}
.epcBandD {
  background-color: #ffd500;
  color: #000000;
  font-weight: bold;
}
.epcBandE {
  background-color: #fcaa65;
  color: #000000;
  font-weight: bold;
}
.epcBandF {
  background-color: #ef8023;
  color: #000000;
  font-weight: bold;
}
.epcBandG {
  background-color: #e9153b;
  color: #000000;
  font-weight: bold;
}
.eirBandA {
  background-color: #d9e9f8;
  color: #000000;
  font-weight: bold;
}
.eirBandB {
  background-color: #acc8ea;
  color: #000000;
  font-weight: bold;
}
.eirBandC {
  background-color: #89a2d2;
  color: #000000;
  font-weight: bold;
}
.eirBandD {
  background-color: #4d86c1;
  color: #000000;
  font-weight: bold;
}
.eirBandE {
  background-color: #b3b0af;
  color: #000000;
  font-weight: bold;
}
.eirBandF {
  background-color: #8d8a8a;
  color: #FFFFFF;
  font-weight: bold;
}
.eirBandG {
  background-color: #6e6c69;
  color: #FFFFFF;
  font-weight: bold;
}

h1.adminDashboard {
  font-size: 20pt;
}

h2.adminDashboard {
  font-size: 15pt;
}

div.dropzone {
  width: 500px;
  height: 150px;
  border: 4px dashed #4f4e4e;
  border-radius: 30px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-color: #f0f0ff;
  position: relative;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.payoutsReport {
  display: grid;
  grid-template-columns: max-content max-content max-content 1fr;
  border: 1px solid black;
}

.payoutsReport .cell {
  border: 1px solid black;
  padding: 8px;
}

.payoutsReport .heading {
  background-color: var(--epims-light-blue);
}

.payoutsReport .monthSummary {
  font-weight: bold;
  font-size: 24px;
  grid-column: span 4;
  background-color: var(--epims-green);
  color: white;
}

.payoutsReport .userSummary {
  font-weight: bold;
  grid-column: span 4;
  background-color: var(--epims-blue);
  color: white;
}
